<template>
  <div id="forgotPassword">
    <Modal
      :isOpen.sync="isModalOpen"
      :header="$t('password.forgot')"
      :loading="{ save: loading }"
      :buttonLabel="$t('button.send')"
      @cancel="() => {}"
      @save="onSubmit"
    >
      <form ref="form" @submit.prevent="onSubmit">
        <Field
          :label="$t('global.email')"
          v-model="myEmail"
          :placeholder="$t('global.email')"
          type="email"
          :validation-message="$t('validations.email')"
          required
          focus
        />
      </form>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Field from '@/components/Field.vue';
import { toast } from '@/utils/dialog';

export default {
  components: {
    Modal,
    Field
  },
  data() {
    return {
      loading: false,
      isModalOpen: false,
      myEmail: this.email
    };
  },
  watch: {
    email(value) {
      this.myEmail = value;
    },
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
    }
  },
  methods: {
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        try {
          const { myEmail } = this;
          await this.Api.post('users/reset_password', { email: myEmail }, { Authorization: 'ok' });
          toast('success', this.$t('login.emailSend'), 5000);
          this.isModalOpen = false;
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    }
  },
  props: {
    email: { type: String, default: null },
    isOpen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#forgotPassword
  ::v-deep
    .modal-title
      i
        padding-left: 4px
form
  margin-bottom: 15px
</style>
